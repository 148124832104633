<template>
  <div
    class="relative bg-gray-900 viewer outer"
    :class="wrapperClass"
  >
    <div class="flex flex-wrap items-center justify-center flex-grow overflow-hidden inner">
      <template v-if="asset && isReady">
        <component
          :is="component"
          :asset="asset"
        />
      </template>
      <template v-if="asset && !isReady">
        <div class="text-center">
          <template v-if="asset.status === 'review' && asset.type === 'scene'">
            <span class="block mb-2 font-mono text-sm text-white">
              <fa-icon icon="check" /> Scene is ready for first run setup
            </span>
            <p class="block mb-4 font-mono text-xs text-gray-400">
              Before you can use this scene, it needs to be processed. <br>Assets will be optimized and sent to the server.
            </p>
            <router-link
              :to="toEditor"
              href="#"
              class="btn primary"
            >
              Review and Enable <fa-icon
                icon="arrow-right"
                class="ml-1"
              />
            </router-link>
          </template>

          <template v-else-if="asset.status === 'deleted'">
            <span class="block mb-2 font-mono text-sm text-white">
              <fa-icon
                :icon="['far', 'frown']"
                size="lg"
              /> Asset deleted
            </span>
          </template>
          <template v-else>
            <span class="block mb-2 font-mono text-sm text-white">
              <fa-icon
                icon="spinner"
                spin
              />
              Processing
            </span>
            <span class="block font-mono text-xs text-gray-400">
              We'll notify you when it's ready. <br>You can navigate away from this page
            </span>
          </template>
        </div>
      </template>
    </div>
  </div>

  </div>
</template>
<script>
const PlaybarContainer = () => import('@components/viewer/playbar/PlaybarContainer')
const SequenceContainer = () => import('@components/viewer/SequenceContainer')
const SceneContainer = () => import('@components/viewer/SceneContainer')
const ImageContainer = () => import('@components/viewer/ImageContainer')
const VideoContainer = () => import('@components/viewer/VideoContainer')
const CompositionContainer = () => import('@components/viewer/CompositionContainer')
const FileContainer = () => import('@components/viewer/FileContainer')

export default {
  name: 'ViewerBlock',

  components: {
    PlaybarContainer,
    SceneContainer,
    SequenceContainer,
    ImageContainer,
    VideoContainer,
    CompositionContainer,
    FileContainer
  },

  props: ['asset', 'viewmode'],

  computed: {

    component () {
      switch (this.asset.type) {
        case 'image':
          return 'ImageContainer'
        case 'video':
          return 'VideoContainer'
        case 'scene':
          return 'SceneContainer'
        case 'sequence':
          return 'SequenceContainer'
        case 'composition':
          return 'CompositionContainer'
        default:
          return 'FileContainer'
      }
    },

    isReady () {
      return this.asset.status === 'ready'
    },

    toEditor () {
      return { name: 'content_edit', params: { uuid: this.asset.id } }
    },

    wrapperClass () {
      if (this.viewmode === 'embed') return 'h-screen'
      return ''
    }
  }
}
</script>
